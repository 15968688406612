
































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import store from '@/services/store';
import { formLst } from '@/modules/budget-request/components/js/budgetFormsLst';
import { variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';

@Component({
    name: 'c-budget-req-head',
    components: {
        'multiselect': Multiselect
    }
})
export default class CLimitFilter extends Vue {
    @Prop({
        required: false,
        default: 'Год'
    })
    private nameYear!: string;

    @Prop({
        required: false,
        default: null
    })
    private filter!: any | null;

    @Prop({
        required: false,
        default: false
    })
    private openDisabled!: boolean; // disabled кнопка открыть

    public openFilterByRef(refName: any) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private get filterMap() {
        if (this.filter === null) { return null; }
        const result = new Map();
        for (const el of this.filter) {
            const abpMap = new Map();
            const abpArr = [];
            for (const abpEl of el.abp) {
                const progMap = new Map();
                if (abpEl.progr !== undefined && abpEl.progr !== null) {
                    for (const progEl of abpEl.progr) {
                        progMap.set(progEl.prg, progEl);
                    }
                }
                const abpResult = Object.assign({ progr: progMap }, abpEl);
                abpMap.set(abpEl.abp, abpResult);
                const abpClear = Object.assign({}, abpEl);
                delete abpClear.progr;
                abpArr.push(abpClear);
            }
            result.set(el.funcGr, { abpMap: abpMap, abp: abpArr });
        }
        return result;
    }

    private budgetLevel: number[] = [];

    // -------------------------------
    private obl: string | null = null;

    private regionBase: any[] = [];
    private curRegion: any | null = null;

    private get regions(): any[] {
        const res = [];
        for (const el of this.regionBase) {
            res.push(this.setNameLang(el, 'code'));
        }
        if (this.curRegion !== null) { this.curRegion = this.setNameLang(this.curRegion, 'code'); }
        res.sort((a, b) => (a.code - b.code > 0) ? 1 : -1);
        return res;
    }

    // -------------------------------

    private curYear = null;
    private year: number | null = null;
    private maxYear = 3000;
    private minYear = 2000;

    private periodLst: any[] = [];
    private curPeriod: any | null = null;

    private getPeriodLst() {
        const year = (new Date()).getFullYear();
        this.periodLst = [];
        for (let i = 0; i < 3; i++) {
            this.periodLst.push({ name: `${year - 1 + i} - ${year - 1 + i + 2}`, year: year - 1 + i });
            if (year === (year - 1 + i + 1)) {
                this.curPeriod = { name: `${year + 1} - ${year + 3}`, year: year + 1 };
                this.year = year + 1;
                this.maxYear = year + 3;
                this.minYear = year + 1;
            }
        }
        this.periodLst.reverse();
    }

    // ---------------- функциональная группа ------------------
    private funcGrBase: any[] = [];
    private curFunGr: any | null = null;

    private get funcGr(): any[] {
        const res = [];
        for (const el of this.funcGrBase) {
            res.push(this.setNameLang(el, 'gr'));
        }
        if (this.curFunGr !== null) { this.curFunGr = this.setNameLang(this.curFunGr, 'gr'); }
        res.sort((a, b) => (a.gr - b.gr > 0) ? 1 : -1);
        return res;
    }

    private async chgFuncGr() {
        await this.loadAbp();
    }


    // ------------------ администратор программ ------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) { this.curAbp = this.setNameLang(this.curAbp, 'abp'); }
        return res;
    }

    // --------- гос учреждение ------------
    private companyBase: any[] = [];
    private curComp: any | null = null;

    private get company(): any[] {
        const result: any[] = [];
        for (const el of this.companyBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }

    private async chgAbp() {
        await this.loadProg();
        await this.loadCompany();
    }

    // ------------------------ программа --------------------------
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) { this.curProg = this.setNameLang(this.curProg, 'prg'); }
        return res;
    }

    private async chgProg() {
        await this.loadSubProg();
    }
    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) {
            this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
        }
        return res;
    }
    // ----------------------------- load -----------------------------------

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    private async loadFuncGR() {
        let response: any = [];
        this.funcGrBase = [];
        if (this.usrId === null) { return; }
        try {
            response = await fetch(`/api-py/get-func-gr-by-user-id/${this.usrId}`);
            this.funcGrBase = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки фун. групп', (error as Error).toString());
            response = [];
        }
        if (this.funcGrBase.length > 0) {
            this.curFunGr = this.funcGrBase[0];
            await this.chgFuncGr();
        }
    }

    private async loadAbp() {
        let response: any = [];
        this.abpBase = [];
        if (this.usrId === null) { return; }
        if (this.filterMap !== null) {
            const abp = this.filterMap.get(this.curFunGr.gr);
            if (abp !== undefined) { response = abp.abp; }
        }
        if (response.length === 0) {
            try {
                response = await fetch(`/api-py/get-abp-by-func-gr-msu-region/${this.curFunGr.gr}/${this.curRegion.code}`);
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
            }
        }
        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
        await this.chgAbp();
    }

    private async loadCompany() {
        let result: any[] = [];
        this.curComp = null;
        if (this.curAbp !== null) {
            try {
                result = await fetch(`/api-py/get-gu-by-abp-user-id/${this.curAbp.abp}/${this.usrId}`)
                    .then(response => response.json());
            } catch (error) {
                result = [];
                this.makeToast('danger', 'Ошибка загрузки гос. учреждений', (error as Error).toString());
            }
            if (result.length > 0) {
                this.curComp = this.setNameLang(result[0], 'code');
            }
            this.companyBase = result;
        }
    }

    private async loadProg() {
        let response: any = [];
        this.progBase = [];
        if (this.filterMap !== null) {
            const abp = this.filterMap.get(this.curFunGr.gr);
            if (abp !== undefined) {
                const curAbp = abp.abpMap.get(this.curAbp.abp);
                if (curAbp !== undefined) {
                    response = curAbp.progr;
                }
            }
        }
        if (response.length === 0 && this.curAbp !== null) {
            try {
                response = await fetch('/api-py/get-dict-func-childs-by-parent-type/' + this.curAbp.id + '/4');
                // response = await fetch('/api-py/get-dict-func-childs-by-parent-type-budgetLevel/' + this.curAbp.id + '/4/' + encodeURI(JSON.stringify(this.budgetLevel)));
                response = await response.json();
                response = response.filter((item: any) => item.develop_type === 0);
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки программ', (error as Error).toString());
            }
        }

        response.sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
        this.progBase = this.setIdArr(response, 'prg');

        if (this.progBase.length > 0) {
            this.curProg = this.progBase[0];
        } else {
            this.curProg = null;
        }
        await this.chgProg();
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];
        if (this.curProg !== null) {
            try {
                response = await fetch('/api-py/get-dict-func-childs-by-parent-type/' + this.curProg.id + '/5');
                // response = await fetch('/api-py/get-dict-func-childs-by-parent-type-budgetLevel/' + this.curProg.id + '/5/' + encodeURI(JSON.stringify([this.budgetLevel])));
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
                response = [];
            }
        }

        response.sort((a: any, b: any) => (a.ppr - b.ppr > 0) ? 1 : -1);
        this.subProgBase = this.setIdArr(response, 'ppr');

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
        } else {
            this.curSubProg = null;
        }
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    private async loadBudgetLevel() {
        this.budgetLevel = [];
        if (!this.usrId) { return; }
        let result = [];
        try {
            result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки уровня бюджета', (error as Error).toString());
            result = [];
            return;
        }
        if (!Array.isArray(result)) { return; }

        for (const el of result) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const indx = el.budget_level.lastIndexOf('_');
            if (indx >= 0) {
                this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
            }
        }
        if (this.budgetLevel.length === 0) { this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!'); }
        await this.loadFuncGR();
    }
    // ----------------------------------

    private async created() {
        await this.getObl();
        this.getPeriodLst();

        this.$watch('usrId', async () => { if (this.usrId) {
            await this.getObl();
            await this.loadBudgetLevel();
        } });
        await this.loadBudgetLevel();
        this.$watch('curPeriod', this.chgCurPeriod);
        // this.loadFormsDict();
        // this.loadDict();
        this.chgData();
    }

    private async chgCurPeriod() {
        if (this.curPeriod) {
            this.curYear = this.curPeriod.year;
            this.year = this.curYear;
            this.maxYear = this.curPeriod.year + 2;
            this.minYear = this.curPeriod.year;
        }
    }

    private async getObl() {
        this.curRegion = [];
        this.regionBase = [];
        if (!this.usrId) { return; }
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);

            if (result.status === 200) {
                const json = await result.json();
                this.obl = json.obl;
            } else {
                this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
        }

        try {
            await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
                .then(response => response.json())
                .then(json => {
                    if (json.length && json[0].code) { this.regionBase = json; }
                });
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
        }

        if (this.regionBase.length > 0) {
            this.curRegion = this.regionBase[0];
        } else {
            this.curRegion = null;
        }
    }

    private async chgRegion() {
        if (this.curFunGr && this.curFunGr.gr) {
            await this.loadAbp();
        }
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        if (obj === null) { return null; }
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private chgData() {
        this.curYear = this.curPeriod.year;
        if (this.year !== null) {
            this.year = parseInt(this.year.toString());
        }
        const data = { year: this.curYear, yearProp: this.year, gr: this.curFunGr, abp: this.curAbp, prg: this.curProg, pgr: this.curSubProg, gu: this.curComp, region: this.curRegion.code, regionDetails: this.curRegion };
        this.$emit('chgData', data);

        const drop: any = this.$refs.drop;
        drop.hide(true);
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
